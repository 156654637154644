import React from "react";
import { CiMail, CiPhone } from "react-icons/ci";
import { socials } from "../../constant";
import { icons } from "react-icons";
import { SlArrowRight } from "react-icons/sl";
import { TbTicket } from "react-icons/tb";
import { AiOutlineBulb } from "react-icons/ai";
import { messQue, lodge } from "../../assets/images";
import { BsArrowLeft } from "react-icons/bs";

const Support = ({ setOpen }: any) => {
  return (
    <div className="w-full font-manrope mt-2">
      <div className="w-full flex lgss:hidden justify-start gap-6 items-center">
        <button
          onClick={() => {
            setOpen(false);
          }}
        >
          <BsArrowLeft className="text-black  text-[20px]" />
        </button>
      </div>
      <div className="flex w-full flex-col lgss:flex-row items-start justify-center gap-6 mt-6">
        <div className="w-full lgss:w-1/3 p-4 border border-gray-100 rounded-xl">
          <div className="">
            <h4 className="text-gray-700 font-bold text-[14px]">
              Need help? Talk to us
            </h4>
            <a
              href="mailto:support@deexoptions.com"
              className="flex items-center gap-2 mt-4"
            >
              <CiMail className="text-[20px] text-gray-700" />
              <h4 className="text-gray-500 font-medium text-[14px]">
                support@deexoptions.com
              </h4>
            </a>
            <a
              href="https://chat.whatsapp.com/K6wZTRSrG4GJM0jdCbRms4"
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 mt-4"
            >
              <CiPhone className="text-[20px] text-gray-700" />
              <h4 className="text-gray-500 font-medium text-[14px]">
                +2348163350001
              </h4>
            </a>
          </div>
          <div className="mt-6 py-3 border-t border-gray-200">
            <h4 className="text-gray-700 font-bold text-[14px]">
              Reach us on social media
            </h4>
            <div className="flex flex-row gap-2 lgss:gap-3 mt-4 mb-2 justify-start items-center">
              {socials.map((social) => (
                <a
                  key={social.id}
                  href={social.link}
                  target="_blank"
                  rel="noreferrer"
                  className="w-[28px] h-[28px] rounded-lg flex justify-center items-center"
                  style={{ backgroundColor: social.color }} // Set dynamic background color
                >
                  <social.icon className="text-white text-[16px]" />{" "}
                  {/* Icon with white color */}
                </a>
              ))}
            </div>
            <a
              href="https://www.deexoptions.com"
              target="_blank"
              rel="noreferrer"
              className=" text-secondary_button font-bold text-[14px] "
            >
              Visit DeeX website
            </a>
          </div>
        </div>
        <div className="w-full mx-auto lgss:w-2/3  rounded-xl">
          <a
            href="https://www.deexoptions.com/about"
            target="_blank"
            rel="noreferrer"
            className="bg-white dark:bg-transparent w-full  block"
          >
            <div className="flex justify-between py-3  px-4 border border-gray-200 shadow-md rounded-xl  items-center cursor-pointer">
              <div className="flex justify-start items-center gap-4">
                <div className="flex justify-center items-center  text-[24px] text-secondary_button w-[32px] h-[32px] rounded-full">
                  <AiOutlineBulb />
                </div>
                <div>
                  <h4 className="text-[#13181B]  font-medium text-[15px]">
                    About Us
                  </h4>
                  <h4 className="text-gray-500  mt-1 text-[13px]">
                    Learn about us
                  </h4>
                </div>
              </div>
              <SlArrowRight className="text-gray-500   text-[16px]" />
            </div>
          </a>
          <a
            href="https://www.deexoptions.com/about"
            target="_blank"
            rel="noreferrer"
            className="bg-white dark:bg-transparent w-full  block"
          >
            <div className="flex justify-between py-3  px-4 border border-gray-200 shadow-md rounded-xl mt-4 items-center cursor-pointer">
              <div className="flex justify-start items-center gap-4">
                <div className="flex justify-center items-center  text-[24px] text-secondary_button w-[24px] h-[24px] rounded-full">
                  <img
                    src={messQue}
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="text-[#13181B]  font-medium text-[15px]">
                    FAQs
                  </h4>
                  <h4 className="text-gray-500  mt-1 text-[13px]">
                    Answered questions about DeeX
                  </h4>
                </div>
              </div>
              <SlArrowRight className="text-gray-500   text-[16px]" />
            </div>
          </a>
          <a
            href="https://www.deexoptions.com/about#contact"
            target="_blank"
            rel="noreferrer"
            className="bg-white dark:bg-transparent w-full  block"
          >
            <div className="flex justify-between py-3  px-4 border border-gray-200 shadow-md rounded-xl mt-4 items-center cursor-pointer">
              <div className="flex justify-start items-center gap-4">
                <div className="flex justify-center items-center  text-[28px] text-secondary_button w-[32px] h-[32px] rounded-full">
                  <TbTicket className="" />
                </div>
                <div>
                  <h4 className="text-[#13181B]  font-medium text-[15px]">
                    Lodge a complaint
                  </h4>
                  <h4 className="text-gray-500  mt-1 text-[13px]">
                    Tell us if anything is wrong{" "}
                  </h4>
                </div>
              </div>
              <SlArrowRight className="text-gray-500  text-[16px]" />
            </div>
          </a>

          <a
            href="https://www.deexoptions.com/privacy"
            target="_blank"
            rel="noreferrer"
            className="bg-white dark:bg-transparent w-full  block"
          >
            <div className="flex justify-between border border-gray-200 shadow-md rounded-xl mt-4 px-4 py-4 items-center cursor-pointer">
              <div className="flex justify-start items-center gap-4">
                <div className="flex justify-center items-center  text-[24px] text-secondary_button w-[24px] h-[24px] rounded-full">
                  <img
                    src={lodge}
                    className="w-full h-full object-cover"
                    alt=""
                  />
                </div>
                <div>
                  <h4 className="text-[#13181B]  font-medium text-[15px]">
                    Terms and Privacy
                  </h4>
                  <h4 className="text-gray-500  mt-1 text-[13px]">
                    Read our privacy policy
                  </h4>
                </div>
              </div>
              <SlArrowRight className="text-gray-500   text-[16px]" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Support;
