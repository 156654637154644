import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
interface Phone {
  number: string;
}
const Profile = ({ setOpen }: any) => {
  const [phoneNumber, setPhoneNumber] = useState<Phone>({
    number: "",
  });

  const handleChangePhoneNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const phoneNumber = event.target.value;
    let formattedPhoneNumber = phoneNumber;

    if (phoneNumber.charAt(0) === "0") {
      formattedPhoneNumber = phoneNumber.slice(1);
    }

    setPhoneNumber({
      number: formattedPhoneNumber,
    });
  };

  return (
    <div className="w-full font-manrope mt-6">
      <div className="w-full flex justify-between lgss:justify-end items-center">
        <div className=" flex lgss:hidden justify-start gap-1 items-center">
          <button
            onClick={() => {
              setOpen(false);
            }}
          >
            <BsArrowLeft className="text-black  text-[20px]" />
          </button>
        </div>
        <button className="p-3 bg-secondary_button text-gray-50 font-bold text-[14px] rounded-xl ">
          Save details
        </button>
      </div>
      <div className="flex w-full flex-col lgss:flex-row justify-center items-start gap-6 mt-6">
        <div className="w-full lgss:w-1/3 p-4 border border-gray-100 rounded-xl">
          <div className="">
            <h4 className="text-gray-700 font-medium text-[14px]">
              DeeX Enterprise
            </h4>
            <h4 className="text-gray-500 mt-1 font-medium text-[14px]">
              app@deex.com
            </h4>
          </div>
          <div className="w-full border-t border-gray-300 py-2 mt-8 flex justify-between items-center">
            <button className=" text-secondary_button text-[14px] font-medium">
              Upload new photo
            </button>
            <button className=" text-[#D92B2B] text-[14px] font-medium">
              Delete photo
            </button>
          </div>
        </div>
        <div className="w-full mx-auto lgss:w-2/3 p-4 border border-gray-100 rounded-xl">
          <div className="w-full">
            <div className="w-full flex flex-col lgss:flex-row gap-4">
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  First name
                </label>
                <input
                  type="text"
                  placeholder="Precious"
                  className="border mt-1 outline-none w-full text-[14px] px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-gray-50"
                />
              </div>
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Last name
                </label>
                <input
                  type="text"
                  placeholder="Ossai"
                  className="border mt-1 outline-none w-full text-[14px] px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-gray-50"
                />
              </div>
            </div>
            <div className=" flex flex-col w-full mt-4">
              <label className="text-[14px] text-[#3A4852] font-medium">
                Email address
              </label>
              <input
                type="email"
                placeholder="preciystar01@gmail.com"
                className="border mt-1 outline-none w-full text-[14px] px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-gray-50"
              />
            </div>
            <div className="flex flex-col w-full mt-4">
              <label className="text-[14px] text-[#3A4852] font-medium">
                Phone Number
              </label>
              <div className="flex mt-1">
                <div className="border text-[#3A4852] text-[14px] font-medium outline-none px-4 h-[46px] flex justify-center items-center rounded-l-xl border-[#C6D0D7] bg-gray-200">
                  +234
                </div>
                <input
                  type="tel"
                  className="border  text-[#3A4852] text-[14px] outline-none w-full px-4 h-[46px] rounded-r-xl border-[#C6D0D7] bg-gray-50"
                  placeholder="Enter phone number"
                  value={`${phoneNumber.number}`}
                  onChange={handleChangePhoneNumber}
                />
              </div>
            </div>
            <button className="mt-4 text-secondary_button text-[14px] font-bold">
              Update login password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
