import React, { useState } from "react";
import { options } from "../../constant";
import Select from "react-select";
import { BsArrowLeft } from "react-icons/bs";

const BusinessDetails = ({ setOpen }: any) => {
  const [businessIndustry, setBusinessIndustry] = useState<any>("");
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: "12px", // Matches `rounded-xl`
      border: "1px solid #C6D0D7", // Matches `border-[#C6D0D7]`
      backgroundColor: "#F7F8F9", // Matches `bg-[#E7EBEE]`
      padding: "0 6px", // Matches `px-4`
      height: "46px", // Matches `h-[46px]`
      boxShadow: "none", // Matches `outline-none`
      fontSize: "14px", // Matches `text-[14px]`
      color: "#4A5568", // Matches `text-gray-700`
      marginTop: "4px",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      ...provided,
      backgroundColor: state.isSelected ? "#C6D0D7" : "#fff", // Highlights selected option
      color: "#3A4852", // Text color for options
      padding: "10px 16px", // Ensure consistent padding
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F7F8F9", // Matches hover styling
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "8px", // Matches rounded-xl
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle dropdown shadow
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#A0AEC0", // Matches placeholder color styling
      fontSize: "14px", // Matches text-[14px]
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#3A4852", // Matches text-[#3A4852]
    }),
  };

  return (
    <div className="w-full font-manrope mt-6">
      <div className="w-full flex justify-between lgss:justify-end items-center">
        <div className=" flex lgss:hidden justify-start gap-6 items-center">
          <button
            onClick={() => {
              setOpen(false);
            }}
          >
            <BsArrowLeft className="text-black  text-[20px]" />
          </button>
        </div>
        <button className="p-3 bg-secondary_button text-gray-50 font-bold text-[14px] rounded-xl ">
          Save details
        </button>
      </div>
      <div className="flex w-full flex-col lgss:flex-row gap-6 justify-center items-start mt-6">
        <div className="w-full lgss:w-1/3 p-4 border border-gray-100 rounded-xl">
          <div className="">
            <h4 className="text-gray-700 font-medium text-[14px]">
              DeeX Enterprise
            </h4>
            <h4 className="text-gray-500 mt-1 font-medium text-[14px]">
              app@deex.com
            </h4>
          </div>
          <div className="w-full border-t border-gray-300 py-2 mt-8 flex justify-between items-center">
            <button className=" text-secondary_button text-[14px] font-medium">
              Update business logo
            </button>
            <button className=" text-[#D92B2B] text-[14px] font-medium">
              Delete logo
            </button>
          </div>
        </div>
        <div className="w-full mx-auto lgss:w-2/3 p-4 border border-gray-100 rounded-xl">
          <div className="w-full">
            <div className="w-full flex flex-col lgss:flex-row gap-4">
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Business name
                </label>
                <input
                  type="text"
                  placeholder="e.g Lionel"
                  className="border mt-1 outline-none w-full text-[14px] px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-gray-50"
                />
              </div>
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Business industry
                </label>
                <Select
                  options={options}
                  styles={customStyles}
                  onChange={(option: any) => setBusinessIndustry(option)}
                />
              </div>
            </div>
            <div className="w-full flex flex-col lgss:flex-row gap-4 mt-4">
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Official email
                </label>
                <input
                  type="email"
                  placeholder=" e.g info@deex.com"
                  className="border mt-1 outline-none w-full text-[14px] px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-gray-50"
                />
              </div>
              <div className=" flex flex-col w-full ">
                <label className="text-[14px] text-[#3A4852] font-medium">
                  Country
                </label>
                <select className="border mt-1 text-gray-700 text-[14px] outline-none w-full  px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-gray-50">
                  <option value={""} disabled>
                    Select country
                  </option>
                  <option value="Nigeria">Nigeria</option>
                </select>
              </div>
            </div>
            <div className=" flex flex-col w-full mt-4">
              <label className="text-[14px] text-[#3A4852] font-medium">
                Address
              </label>
              <input
                type="text"
                placeholder="Enter full address of business"
                className="border mt-1 outline-none w-full text-[14px] px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-gray-50"
              />
            </div>
            <div className=" flex flex-col w-full mt-4">
              <label className="text-[14px] text-[#3A4852] font-medium">
                Size
              </label>
              <select className="border mt-1 text-gray-700 text-[14px] outline-none w-full px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-gray-50">
                <option disabled value="">
                  Select business size
                </option>
                <option value="1-10">1-10 employees</option>
                <option value="11-50">11-50 employees</option>
                <option value="51-100">51-100 employees</option>
                <option value="101 +">101 + employees</option>
              </select>
            </div>
            <div className=" flex flex-col w-full mt-4">
              <label className="text-[14px] text-[#3A4852] font-medium">
                Business description
              </label>
              <textarea
                placeholder="Describe your business. What is it about? How long has it been in operation..."
                className="border outline-none pt-2 mt-1 text-[14px] w-full px-4 h-[89px] rounded-xl border-[#C6D0D7] bg-gray-50"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetails;
