import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineBars3 } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { smallLogo } from "../assets/images";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useUser } from "../context/user-context";
import { useQuery } from "@tanstack/react-query";
import { API } from "../constant/api";
import useAuthAxios from "../utils/baseAxios";
import { useAuth } from "../context/auth-context";
import NotificationModal from "./NotificationModal";

const Navbar = () => {
  const { token } = useAuth();
  const { setIsOpen, isNotified, setIsNotified, setActiveStatus } = useUser();

  const axiosInstance = useAuthAxios();

  const fetchUserData = async () => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const response = await axiosInstance.get(API.getUser);
        resolve(response.data);
      }, 3000);
    });
  };
  const { data: userData }: any = useQuery({
    queryKey: ["userProfile", token],
    queryFn: fetchUserData,
    enabled: !!token, // Ensure the query only runs if a token is available
  });

  const firstInitial = userData?.data.user.profile.firstName || "";
  const secondInitial = userData?.data.user.profile.lastName || "";

  // Fetch unread notifications count
  const fetchUnreadNotifications = async () => {
    const response = await axiosInstance.get(API.getNotifications(1));
    return response.data;
  };

  const { data: notificationsData } = useQuery({
    queryKey: ["unreadNotifications", token],
    queryFn: fetchUnreadNotifications,
    enabled: !!token,
  });

  const unreadNotify =
    notificationsData?.data.meta.noOfUnreadInAppNotifications || 0;
  return (
    <div className="flex justify-between font-manrope border-b items-center shadow bg-white pt-4 pb-4  lgss:pt-4 lgss:w-full  px-5 lgss:px-12">
      <div className="flex justify-center items-center gap-4 lgss:gap-2 ">
        <HiOutlineBars3
          className=" text-black lgss:hidden text-[32px]"
          onClick={() => setIsOpen((prev: any) => !prev)}
        />
        <div className="">
          <img
            src={smallLogo}
            className="w-[90px] h-[25px] hidden lgss:flex"
            alt="logo"
          />
        </div>
        {/* <h4 className="font-sora">
          {location.pathname === "/crypto"
            ? "Crypto"
            : location.pathname === "/giftcards"
            ? "Giftcards"
            : location.pathname === "/p"
            ? "Payouts"
            : location.pathname === "/settings"
            ? "Settings"
            : null}
        </h4> */}
      </div>
      <div className="flex gap-4  justify-center items-center ">
        <Link
          to={"/settings"}
          onClick={() => setActiveStatus(5)}
          className="bg-[#F1F3F5] w-[42px] h-[42px] rounded-full flex justify-center items-center"
        >
          <IoSettingsOutline className="text-2xl lgss:text-3xl" />
        </Link>
        <div className="bg-[#F1F3F5] w-[42px] h-[42px] rounded-full flex justify-center items-center">
          <div className="relative">
            <IoIosNotificationsOutline
              onClick={() => setIsNotified((prev: any) => !prev)}
              className="text-2xl lgss:text-3xl text-black cursor-pointer"
            />
            {unreadNotify >= 1 && (
              <div className="bell-light absolute top-0 animate-pulse -right-[1px] w-2 h-2 bg-secondary_button rounded-full" />
            )}
          </div>
        </div>
        <Link
          to=""
          className="rounded-full hidden w-[42px] h-[42px] bg-[#D4EBFD] lgss:flex items-center justify-center font-medium text-secondary_button cursor-pointer"
        >
          {firstInitial.charAt(0)}
          {secondInitial.charAt(0)}
        </Link>
      </div>
      {isNotified && <NotificationModal setIsNotified={setIsNotified} />}
    </div>
  );
};

export default Navbar;
