import React, { useEffect, useState } from "react";
import useAuthAxios from "../../utils/baseAxios";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { API } from "../../constant/api";
import { errorMessage } from "../../utils/errorMessage";
import DeexPending from "../../components/DeexPending";
import Profile from "./Profile";
import BusinessDetails from "./BusinessDetails";
import UploadDocuments from "./UploadDocuments";
import Banks from "./Banks";
import Support from "./Support";
import { SlArrowRight } from "react-icons/sl";

const Settings = () => {
  const axiosInstance = useAuthAxios();
  const [screen, setScreen] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);

  const getBusinesses = async () => {
    const response = await axiosInstance.get(API.getBusinesses);
    return response?.data?.data.businesses;
  };

  const {
    data: businesses,
    error: error1,
    isLoading,
  } = useQuery({
    queryKey: ["get-businesses"],
    queryFn: getBusinesses,
    retry: 1,
  });

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  const getBusiness = async () => {
    if (businesses && businesses.length > 0 && businesses[0]._id) {
      const response = await axiosInstance.get(
        API.getBusiness(businesses[0]?._id, "", "")
      );
      return response.data.data;
    }
    return null;
  };

  const { data: business, error: error2 } = useQuery({
    queryKey: ["get-business"],
    queryFn: getBusiness,
    retry: 1,
    enabled: !!(businesses && businesses?.length > 0 && businesses[0]?._id),
  });

  useEffect(() => {
    if (error2) {
      const newError = error2 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error2]);
  const banks = business?.business?.banks;
  return (
    <div
      className={
        businesses?.length >= 1 || businesses
          ? "w-full font-manrope lgss:h-screen "
          : "w-full h-screen font-manrope"
      }
    >
      <Navbar />
      <div
        className={
          businesses?.length >= 1 || businesses
            ? "lgss:flex  lgss:flex-row font-manrope w-full overflow-auto bg-[#F1F3F5]   h-screen"
            : "lgss:flex  lgss:flex-row font-manrope w-full  bg-[#F1F3F5]  h-screen"
        }
      >
        <Sidebar />
        <div className="w-full h-full pt-16 overflow-auto px-6 pb-20  font-manrope  lgss:px-20  lgss:w-5/6">
          <div className="flex md:justify-between flex-col md:flex-row justify-start items-start md:tems-center">
            <h2 className="font-sora text-[24px] font-semibold text-[#3A4852]">
              Settings
            </h2>
          </div>
          <div className="mt-12 bg-white w-full px-4 mds:px-6 lgss:px-10 xxl:px-20 py-6 rounded-lg">
            {!open && (
              <div className="w-full lgss:hidden">
                <button
                  onClick={() => {
                    setScreen(1);
                    setOpen(true);
                  }}
                  className="bg-white dark:bg-transparent w-full  "
                >
                  <div className="flex justify-between border border-gray-200 shadow-md rounded-xl  px-4 py-4 items-center cursor-pointer">
                    <div className="">
                      <div>
                        <h4 className="text-[#13181B] text-left font-medium text-[15px]">
                          Merchant Profile
                        </h4>
                        <h4 className="text-gray-500  mt-1 text-[13px]">
                          Edit your personal profile
                        </h4>
                      </div>
                    </div>
                    <SlArrowRight className="text-gray-500   text-[16px]" />
                  </div>
                </button>
                <button
                  onClick={() => {
                    setScreen(2);
                    setOpen(true);
                  }}
                  className="bg-white dark:bg-transparent w-full  "
                >
                  <div className="flex justify-between border border-gray-200 shadow-md rounded-xl mt-4 px-4 py-4 items-center cursor-pointer">
                    <div className="">
                      <div>
                        <h4 className="text-[#13181B] text-left font-medium text-[15px]">
                          Business Details
                        </h4>
                        <h4 className="text-gray-500  mt-1 text-[13px]">
                          Set up details of your business
                        </h4>
                      </div>
                    </div>
                    <SlArrowRight className="text-gray-500   text-[16px]" />
                  </div>
                </button>
                <button
                  onClick={() => {
                    setScreen(3);
                    setOpen(true);
                  }}
                  className="bg-white dark:bg-transparent w-full  "
                >
                  <div className="flex justify-between border border-gray-200 shadow-md rounded-xl mt-4 px-4 py-4 items-center cursor-pointer">
                    <div className="">
                      <div>
                        <h4 className="text-[#13181B] text-left font-medium text-[15px]">
                          Upload Documents
                        </h4>
                        <h4 className="text-gray-500  mt-1 text-[13px]">
                          Upload required documents
                        </h4>
                      </div>
                    </div>
                    <SlArrowRight className="text-gray-500   text-[16px]" />
                  </div>
                </button>
                <button
                  onClick={() => {
                    setScreen(6);
                    setOpen(true);
                  }}
                  className="bg-white dark:bg-transparent w-full  "
                >
                  <div className="flex justify-between border border-gray-200 shadow-md rounded-xl mt-4 px-4 py-4 items-center cursor-pointer">
                    <div className="">
                      <div>
                        <h4 className="text-[#13181B] text-left font-medium text-[15px]">
                          Bank Details
                        </h4>
                        <h4 className="text-gray-500  mt-1 text-[13px]">
                          Check and add your bank details
                        </h4>
                      </div>
                    </div>
                    <SlArrowRight className="text-gray-500   text-[16px]" />
                  </div>
                </button>
                <button
                  onClick={() => {
                    setScreen(5);
                    setOpen(true);
                  }}
                  className="bg-white dark:bg-transparent w-full  "
                >
                  <div className="flex justify-between border border-gray-200 shadow-md rounded-xl mt-4 px-4 py-4 items-center cursor-pointer">
                    <div className="">
                      <div>
                        <h4 className="text-[#13181B] text-left font-medium text-[15px]">
                          Support
                        </h4>
                        <h4 className="text-gray-500  mt-1 text-[13px]">
                          Contact us, Terms of Service
                        </h4>
                      </div>
                    </div>
                    <SlArrowRight className="text-gray-500   text-[16px]" />
                  </div>
                </button>
              </div>
            )}
            <div className="w-full hidden lgss:w-[83%] xxl:w-[50%] lgss:grid grid-cols-5 overflow-auto bg-[#F1F3F5]  lgss:mx-0   p-2 items-center justify-center rounded-full ">
              <button
                onClick={() => setScreen(1)}
                className={
                  screen === 1
                    ? " text-[9px] md:text-[11px] font-bold text-white bg-gray-700 rounded-full h-[60px]  px-2"
                    : " text-[9px] md:text-[11px] font-bold text-gray-700  rounded-full h-[60px] px-2"
                }
              >
                Merchant Profile
              </button>
              <button
                onClick={() => setScreen(2)}
                className={
                  screen === 2
                    ? " text-[9px] md:text-[11px] font-bold text-white bg-gray-700 rounded-full h-[60px]  px-2"
                    : " text-[9px] md:text-[11px] font-bold text-gray-700  rounded-full h-[60px] px-2"
                }
              >
                Business Details
              </button>
              <button
                onClick={() => setScreen(3)}
                className={
                  screen === 3
                    ? " text-[9px] md:text-[11px] font-bold text-white bg-gray-700 rounded-full h-[60px]  px-2"
                    : " text-[9px] md:text-[11px] font-bold text-gray-700  rounded-full h-[60px]  px-2"
                }
              >
                Upload documents
              </button>
              {/* <button
                onClick={() => setScreen(4)}
                className={
                  screen === 4
                    ? " text-[9px] md:text-[11px] font-bold text-white bg-gray-700 rounded-full h-[60px]  px-2"
                    : " text-[9px] md:text-[11px] font-bold text-gray-700  rounded-full h-[60px]  px-2"
                }
              >
                App settings
              </button> */}
              <button
                onClick={() => setScreen(6)}
                className={
                  screen === 6
                    ? " text-[9px] md:text-[11px] font-bold text-white bg-gray-700 rounded-full h-[60px]  px-2"
                    : " text-[9px] md:text-[11px] font-bold text-gray-700  rounded-full h-[60px]  px-2"
                }
              >
                Banks
              </button>
              <button
                onClick={() => setScreen(5)}
                className={
                  screen === 5
                    ? " text-[9px] md:text-[11px] font-bold text-white bg-gray-700 rounded-full h-[60px]  px-2"
                    : " text-[9px] md:text-[11px] font-bold text-gray-700  rounded-full h-[60px] px-2"
                }
              >
                Support
              </button>
            </div>
            <div className="w-full lgss:hidden ">
              {open && (
                <div className="w-full">
                  {screen === 1 ? (
                    <Profile setOpen={setOpen} />
                  ) : screen === 2 ? (
                    <BusinessDetails setOpen={setOpen} />
                  ) : screen === 3 ? (
                    <UploadDocuments setOpen={setOpen} />
                  ) : screen === 6 ? (
                    <Banks
                      banks={banks}
                      businessID={businesses[0]?._id}
                      setOpen={setOpen}
                    />
                  ) : screen === 5 ? (
                    <Support setOpen={setOpen} />
                  ) : null}
                </div>
              )}
            </div>
            <div className="w-full hidden lgss:grid">
              {screen === 1 ? (
                <Profile setOpen={setOpen} />
              ) : screen === 2 ? (
                <BusinessDetails setOpen={setOpen} />
              ) : screen === 3 ? (
                <UploadDocuments setOpen={setOpen} />
              ) : screen === 6 ? (
                <Banks
                  banks={banks}
                  businessID={businesses[0]?._id}
                  setOpen={setOpen}
                />
              ) : screen === 5 ? (
                <Support setOpen={setOpen} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {!isLoading && (!businesses || businesses.length < 1) ? (
        <DeexPending />
      ) : null}{" "}
    </div>
  );
};

export default Settings;
