import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import { API_URL } from "../../constant";
import { useAuth } from "../../context/auth-context";
import { useUser } from "../../context/user-context";
import { BsArrowLeft } from "react-icons/bs";

const UploadDocuments = ({ setOpen }: any) => {
  const [idImage, setIdImage] = useState<string | any>("");
  const [addImage, setAddImage] = useState<string | any>("");
  const [cacImage, setCacImage] = useState<string | any>("");
  const [identitySignedUrl, setIdentitySignedUrl] = useState<string>("");
  const [addressSignedUrl, setAddressSignedUrl] = useState<string>("");
  const [cacSignedUrl, setCacSignedUrl] = useState<string>("");
  const [idImageName, setIdImageName] = useState<string | null>(null);
  const [addImageName, setAddImageName] = useState<string | null>(null);
  const [cacImageName, setCacImageName] = useState<string | null>(null);
  const { token } = useAuth();
  const {
    setAddressUrl,
    setIdentityUrl,
    setCacUrl,
    setUploadModal,
    bnNumber,
    setBnNumber,
  } = useUser();

  const handleAddImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedImage = event.target.files![0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const csv: string | ArrayBuffer | null = reader.result;
      if (typeof csv === "string") {
        setAddImage(csv);
        setAddImageName(uploadedImage.name);
      }
    };
    reader.readAsDataURL(uploadedImage);
  };

  const handleCacImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedImage = event.target.files![0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const csv: string | ArrayBuffer | null = reader.result;
      if (typeof csv === "string") {
        setCacImage(csv);
        setCacImageName(uploadedImage.name);
      }
    };
    reader.readAsDataURL(uploadedImage);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedImage = event.target.files![0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const csv: string | ArrayBuffer | null = reader.result;
      if (typeof csv === "string") {
        setIdImage(csv);
        setIdImageName(uploadedImage.name);
      }
    };
    reader.readAsDataURL(uploadedImage);
  };

  const getSignedUrl = () => {
    axios
      .post(
        `${API_URL}api/businesses/documents/request-upload-url`,
        {
          documentType: "IDENTITY",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        setIdentitySignedUrl(r.data.data.signedURL);
        setIdentityUrl(r.data.data.objectURL);
      })
      .catch((e: any) => {
        toast.error(e.response.data.message);
        //   setSuccess(false);
        console.log(e.respsonse.data);
      });
  };
  const getaddSignedUrl = () => {
    axios
      .post(
        `${API_URL}api/businesses/documents/request-upload-url`,
        {
          documentType: "PROOF-OF-ADDRESS",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        setAddressSignedUrl(r.data.data.signedURL);
        setAddressUrl(r.data.data.objectURL);
      })
      .catch((e: any) => {
        toast.error(e.response.data.message);
        //   setSuccess(false);
        console.log(e.respsonse.data);
      });
  };
  const getCacSignedUrl = () => {
    axios
      .post(
        `${API_URL}api/businesses/documents/request-upload-url`,
        {
          documentType: "CAC",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((r: any) => {
        setCacSignedUrl(r.data.data.signedURL);
        setCacUrl(r.data.data.objectURL);
      })
      .catch((e: any) => {
        toast.error(e.response.data.message);
        //   setSuccess(false);
        console.log(e.respsonse.data);
      });
  };
  // useEffect(() => {
  //   if (cacUrl === "" || identityUrl === "" || addressUrl === "") {
  //     setDocumentNotloaded(true);
  //   } else {
  //     setDocumentNotloaded(false);
  //   }
  // }, [addressUrl, cacUrl, identityUrl, setDocumentNotloaded]);
  useEffect(() => {
    if (idImage) {
      const formData = new FormData();
      formData.append("file", idImage);
      const contentType = `multipart/form-data`;
      axios
        .put(
          `${identitySignedUrl}`,
          {
            formData,
          },
          {
            headers: {
              // Authorization: `Bearer ${token}`,
              "Content-Type": contentType,
            },
          }
        )
        .then((r: any) => {
          toast.success("Uploaded successfully");
          // setIdentitySignedUrl(r.data.data.signedURL);
          // console.log(identitySignedUrl);
        })
        .catch((e: any) => {
          toast.error(e.response.data.message);
          //   setSuccess(false);
          console.log(e.respsonse.data);
        });
    }
  }, [idImage, identitySignedUrl]);
  useEffect(() => {
    if (addImage) {
      const formData = new FormData();
      formData.append("file", addImage);
      const contentType = `multipart/form-data`;
      axios
        .put(
          `${addressSignedUrl}`,
          {
            formData,
          },
          {
            headers: {
              // Authorization: `Bearer ${token}`,
              "Content-Type": contentType,
            },
          }
        )
        .then((r: any) => {
          toast.success("Uploaded successfully");
          // setIdentitySignedUrl(r.data.data.signedURL);
          // console.log(identitySignedUrl);
        })
        .catch((e: any) => {
          toast.error(e.response.data.message);
          //   setSuccess(false);
          console.log(e.respsonse.data);
        });
    }
  }, [addImage, addressSignedUrl]);
  useEffect(() => {
    if (cacImage) {
      const formData = new FormData();
      formData.append("file", cacImage);
      const contentType = `multipart/form-data`;
      axios
        .put(
          `${cacSignedUrl}`,
          {
            formData,
          },
          {
            headers: {
              // Authorization: `Bearer ${token}`,
              "Content-Type": contentType,
            },
          }
        )
        .then((r: any) => {
          toast.success("Uploaded successfully");
          // setIdentitySignedUrl(r.data.data.signedURL);
          // console.log(identitySignedUrl);
        })
        .catch((e: any) => {
          toast.error(e.response.data.message);
          //   setSuccess(false);
          console.log(e.respsonse.data);
        });
    }
  }, [cacImage, cacSignedUrl]);

  const validateFields = (
    bnNumber: string,
    idImage: string,
    addImage: string,
    cacImage: string
  ) => {
    if (!bnNumber) {
      toast.error("Kindly add your BN Number");

      return false;
    }
    if (!idImage) {
      toast.error("Kindly add your Identity document");

      return false;
    }
    if (!addImage) {
      toast.error("Kindly add a proof of your business address");

      return false;
    }
    if (!cacImage) {
      toast.error("Kindly add your CAC document");

      return false;
    }
    // check if confirmed password is empty

    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateFields(bnNumber, idImage, addImage, cacImage)) {
      return;
    } else {
      setUploadModal((prev: any) => !prev);
    }
  };
  return (
    <div className="w-full lgss:w-2/3 mx-auto transition-all ease-in duration-1000  rounded-xl bg-white py-4">
      <div className="w-full flex lgss:hidden justify-start gap-6 items-center">
        <button
          onClick={() => {
            setOpen(false);
          }}
        >
          <BsArrowLeft className="text-black  text-[20px]" />
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className=" flex flex-col mt-4 w-full ">
          <label className="text-[14px] text-[#3A4852] font-medium">
            RN Number
          </label>
          <input
            type="tel"
            value={bnNumber}
            onChange={(e) => setBnNumber(e.target.value)}
            placeholder="0123456789"
            className="border mt-1 outline-none w-full text-[14px]  px-4 h-[46px] rounded-xl border-[#C6D0D7] bg-gray-50"
          />
        </div>
        <div className=" flex flex-col mt-8 w-full  ">
          <h4 className="text-[14px] text-[#3A4852] font-medium">
            Identity document
          </h4>
          <p className="text-[11px] mt-1 text-[#617889] font-medium">
            Merchant’s valid government ID (e.g Driver’s license, Passport,
            Voter’s card)
          </p>
          {idImageName !== null ? (
            <div className="border flex justify-between items-center px-2 border-[#C6D0D7] rounded-xl w-full h-[45px]">
              <div className="bg-[#E8F3FC] gap-4 h-2/3 flex justify-center items-center text-[13px] font-medium px-2 text-secondary_button">
                {idImageName}
                {/* <FaTimes
                  onClick={() => setImage("")}
                  className="cursor-pointer text-secondary_button "
                /> */}
              </div>
            </div>
          ) : (
            <div
              onClick={getSignedUrl}
              className="relative inline-block cursor-pointer"
            >
              <label htmlFor="image-upload" className="cursor-pointer">
                <button className="rounded-lg bg-[#F7F8F9] border border-dashed border-[#C6D0D7] w-full font-medium text-[14px] h-[45px] mt-2 flex justify-center items-center text-secondary_button">
                  Choose a file to upload
                </button>
              </label>
              <input
                type="file"
                id="image-upload"
                // value={}
                onChange={handleImageUpload}
                // style={{ display: "none" }}
                accept="image/*"
                className="absolute w-48 top-4 left-40 opacity-0 h-20"
              />
            </div>
          )}
        </div>
        <div className=" flex flex-col mt-8 w-full ">
          <h4 className="text-[14px] text-[#3A4852] font-medium">
            Proof of address
          </h4>
          <p className="text-[11px] mt-1 text-[#617889] font-medium">
            Any valid document clearly displaying merchant’s address and not
            more than 4 months old (e.g Utility bill )
          </p>
          {addImageName !== null ? (
            <div className="border flex justify-between items-center px-2 border-[#C6D0D7] rounded-xl w-full h-[45px]">
              <div className="bg-[#E8F3FC] gap-4 h-2/3 flex justify-center items-center text-[13px] font-medium px-2 text-secondary_button">
                {addImageName}
                {/* <FaTimes
                  onClick={() => setImage("")}
                  className="cursor-pointer text-secondary_button "
                /> */}
              </div>
            </div>
          ) : (
            <div
              onClick={getaddSignedUrl}
              className="cursor-pointer relative inline-block"
            >
              <label htmlFor="image-upload" className="cursor-pointer">
                <button className="rounded-lg bg-[#F7F8F9] border border-dashed border-[#C6D0D7] w-full font-medium text-[14px] h-[45px] mt-2 flex justify-center items-center text-secondary_button">
                  Choose a file to upload
                </button>
              </label>
              <input
                type="file"
                id="image-upload"
                onChange={handleAddImageUpload}
                // style={{ display: "none" }}
                accept="image/*"
                className="absolute w-48 top-4 left-40 opacity-0 h-20"
              />
            </div>
          )}
        </div>
        <div className=" flex flex-col mt-8 w-full  ">
          <h4 className="text-[14px] text-[#3A4852] font-medium">
            CAC document
          </h4>
          <p className="text-[11px] mt-1 text-[#617889] font-medium">
            Business registration certificate or CAC document
          </p>
          {cacImageName !== null ? (
            <div className="border flex justify-between items-center px-2 border-[#C6D0D7] rounded-xl w-full h-[45px]">
              <div className="bg-[#E8F3FC] gap-4 h-2/3 flex justify-center items-center text-[13px] font-medium px-2 text-secondary_button">
                {cacImageName}
                {/* <FaTimes
                  onClick={() => setImage("")}
                  className="cursor-pointer text-secondary_button "
                /> */}
              </div>
            </div>
          ) : (
            <div onClick={getCacSignedUrl} className="relative inline-block">
              <label htmlFor="image-upload" className="cursor-pointer">
                <button className="rounded-lg bg-[#F7F8F9] border border-dashed border-[#C6D0D7] w-full font-medium text-[14px] h-[45px] mt-2 flex justify-center items-center text-secondary_button">
                  Choose a file to upload
                </button>
              </label>
              <input
                type="file"
                id="image-upload"
                onChange={handleCacImageUpload}
                // style={{ display: "none" }}
                accept="image/*"
                className="absolute w-48 top-4 left-40 opacity-0 h-20"
              />
            </div>
          )}
        </div>

        <button
          type="submit"
          className="flex justify-center items-center rounded-xl w-full mx-auto mt-8 h-[50px] bg-secondary_button text-white "
        >
          Done
        </button>
      </form>
    </div>
  );
};

export default UploadDocuments;
